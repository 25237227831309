import React from "react";
import styled, {css} from 'styled-components'

import Vanta from "../components/vanta"

import Book from "../components/book"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import Cover from "../images/bookCoverSample.jpg"
import sample27 from "../images/sample-27.png"
import sample26 from "../images/sample-26.png"
import sample25 from "../images/sample-25.png"
import sample24 from "../images/sample-24.png"
import sample23 from "../images/sample-23.png"
import sample22 from "../images/sample-22.png"
import sample21 from "../images/sample-21.png"
import sample20 from "../images/sample-20.png"
import sample19 from "../images/sample-19.png"
import sample18 from "../images/sample-18.png"
import sample17 from "../images/sample-17.png"
import sample16 from "../images/sample-16.png"
import sample15 from "../images/sample-15.png"
import sample14 from "../images/sample-14.png"
import sample13 from "../images/sample-13.png"
import sample12 from "../images/sample-12.png"
import sample11 from "../images/sample-11.png"
import sample10 from "../images/sample-10.png"
import sample8 from "../images/sample-8.png"
import sample7 from "../images/sample-7.png"
import sample6 from "../images/sample-6.png"
import sample5 from "../images/sample-5.png"
import sample4 from "../images/sample-4.png"
import sample3 from "../images/sample-3.png"
import sample2 from "../images/sample-2.png"
import sample0 from "../images/sample-0.png"


const IntroText = styled.div`
  padding: 90px 0;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    padding: 0;
    margin-top: 60px;
    margin-bottom: 90px;
  }
`

const BookContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    margin-top: 90px;
  }
`

const StyledButton = styled.button`
  background: #68DE79;
  text-decoration: none;
  border-radius: 3px;
  border: none;
  
  padding: 0.25em 1em;
  
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #FFF;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: start;
  margin: 0;

  :hover{
    background-color: #7bed8b;
    transform: translateY(-1px);
    cursor: pointer;
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
    transition: all .10s ease;
  }

  @media only screen and (max-width: 768px) {
    align-self: center;
    margin-top: 30px;
  }


  ${props => 
    props.primary && 
    css`
      background: #68DE79;
      color: white;
    `
  }
`

const StyledLink = styled.a`
  background: #68DE79;
  text-decoration: none;
  border-radius: 3px;
  border: none;
  
  padding: 0.25em 1em;
  
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #FFF;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: start;
  margin: 0;

  :hover{
    background-color: #7bed8b;
    transform: translateY(-1px);
    cursor: pointer;
    box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
    transition: all .10s ease;
  }

  @media only screen and (max-width: 768px) {
    align-self: center;
    margin-top: 30px;
  }


  ${props => 
    props.primary && 
    css`
      background: #68DE79;
      color: white;
    `
  }
`

function MyVerticallyCenteredModal(props) {
return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Intellectual Anarchy Sample Chapter <StyledLink  href="Sample.pdf"> Download PDF </StyledLink>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <StyledImg src={Cover} alt=""/>
          <StyledImg src={sample0} alt=""/>
          {/* <StyledImg src={sample1} alt=""/> */}
          <StyledImg src={sample2} alt=""/>
          <StyledImg src={sample3} alt=""/>
          <StyledImg src={sample4} alt=""/>
          <StyledImg src={sample5} alt=""/>
          <StyledImg src={sample6} alt=""/>
          <StyledImg src={sample7} alt=""/>
          <StyledImg src={sample8} alt=""/>
          {/* <StyledImg src={sample9} alt=""/> */}
          <StyledImg src={sample10} alt=""/>
          <StyledImg src={sample11} alt=""/>
          <StyledImg src={sample12} alt=""/>
          <StyledImg src={sample13} alt=""/>
          <StyledImg src={sample14} alt=""/>
          <StyledImg src={sample15} alt=""/>
          <StyledImg src={sample16} alt=""/>
          <StyledImg src={sample17} alt=""/>
          <StyledImg src={sample18} alt=""/>
          <StyledImg src={sample19} alt=""/>
          <StyledImg src={sample20} alt=""/>
          <StyledImg src={sample21} alt=""/>
          <StyledImg src={sample22} alt=""/>
          <StyledImg src={sample23} alt=""/>
          <StyledImg src={sample24} alt=""/>
          <StyledImg src={sample25} alt=""/>
          <StyledImg src={sample26} alt=""/>
          <StyledImg src={sample27} alt=""/>
          

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
}



function Intro({ className, children}) {
  const [modalShow, setModalShow] = React.useState(false);
  return(
    <div className={className}>
        <Vanta>
          <div className="container">      
            <div className="row">

              <BookContainer className="col-md-4">
                <Book/>
              </BookContainer>

              <div className="col-md-8">
                <IntroText>
                  <h1>
                    Intellectual Anarchy
                  </h1>
                  <h2>
                    The Art of Disruptive Innovation
                  </h2>

                  <p>
                    A new book by Dr. Patrick Sullivan, founder and CEO of Oceanit Laboratories.
                  </p>
                  {/* <Button href="google.com" primary>Read a Sample</Button> */}
                  
                     <StyledButton variant="primary" onClick={() => setModalShow(true)}>
                       Read a Sample
                     </StyledButton>


                     <MyVerticallyCenteredModal
                       show={modalShow}
                       onHide={() => setModalShow(false)}
                     />
                   
                </IntroText>
              </div>
            </div>
          </div>
        </Vanta>
    </div>
  )
}


const StyledImg = styled.img`
  margin-bottom: 0;
  width: 100%;
  box-shadow: 0 6.3px 12.5px -2.5px rgba(50,50,93,.25), 0 3.8px 7.5px -3.7px rgba(0,0,0,.3);
`


const StyledIntro = styled(Intro)`
  /*margin-right: 30px;*/

  p {
    /*color: "#525f7f";*/
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
  }

  h1 {
    /*color: #32325d;*/
    color: #FFFFFF;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 50px;
  }

  h2 {
    font-size: 24px;
    font-family: 'Open Sans', sans-serif;
    /*color: #525f7f;*/
    color: #FFFFFF;
    line-height: 32px;
    font-weight: 400;
  }

  @media only screen and (max-width: 768px) {
    p, h1, h2 {
      text-align: center;
    }
  }

`

export default StyledIntro