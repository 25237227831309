import React from 'react'
import * as THREE from 'three'
import WAVES from 'vanta/dist/vanta.waves.min.js'


class Vanta extends React.Component {
  constructor(...args) {
    super(...args);


    this.vantaRef = React.createRef()
    // Ref to hook into graph methods.
    // this.graphRef = React.createRef();
    // Setup graph nodes data.
    

    // Bind functions to instance.
  }

  // Update angle in an interval.
  componentDidMount() {
    this.vantaEffect = WAVES({
      el: this.vantaRef.current,
      THREE: THREE // use a custom THREE when initializing
    })
  }

  // Remove the interval on unmount to avoid memory leaks.
  componentWillUnmount() {
    if (this.vantaEffect) this.vantaEffect.destroy()
  }

  // Update the camera position to the next tick.
  

  render() {
    const { children } = this.props;
    return (
      <div ref={this.vantaRef}>
        {children}
      </div>
    );
  }
}


export default Vanta;